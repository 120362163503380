import react from "react";

export interface ISolutionProps {
  icon?: react.ReactNode;
  title: react.ReactNode;
  description?: react.ReactNode;
}

const Solution = (props: ISolutionProps) => {
  const { icon, title, description } = props;

  return (
    <div className="flex flex-row py-4 pl-12 pr-10 rounded-[100px] bg-[#1F2123] items-center justify-center gap-4">
      <div>{icon}</div>
      <div className="flex flex-col">
        <div>{title}</div>
        <div>{description}</div>
      </div>
    </div>
  );
};

export default Solution;
